import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import AccordionPage from '../components/accordion-content/accordion-page'
import privacyPolicy from '../assets/privacy-policy.json'
const PrivacyPolicy = props => (
  <Layout location={props.location}>
    <SEO
      title="Privacy Policy"
      keywords={[
        `novartis`,
        `medgrocer`,
        `pulse care`,
        `entresto`,
        `sacubitril-valsartan`,
      ]}
    />
    <AccordionPage content={privacyPolicy} title="Privacy Policy" hasPreface>
      <p>
        User should carefully read the entire following privacy statement before
        creating an account and/or availing MedGrocer’s products. This agreement
        contains important terms that affect User’s legal rights. By registering
        to MedGrocer’s site, User hereby agrees to the terms of this privacy
        statement policy. MedGrocer respects the privacy of its Users and
        complies with Republic Act No. 10173 also known as the Data Privacy Act
        of 2012, its implementing rules and regulations, and other issuances of
        the National Privacy Commission. MedGrocer recognizes the importance of
        protecting information collected from Users and has adopted this privacy
        policy to inform Users about how MedGrocer collects, uses, stores,
        transfers, discloses, and disposes information derived from their use of
        MedGrocer products, services, and online sites. This privacy statement
        is current as of its last revision date. However, please note that this
        privacy statement may be amended from time to time to reflect changes
        and additions to the privacy policy. Please check back for the most
        current version before relying on any of the provisions in this privacy
        statement.
      </p>
    </AccordionPage>
  </Layout>
)

export default PrivacyPolicy
